<template>
    <div class="finish-wrap">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="info-box">
            <div class="box-top">
                <div class="left">
                    <span>{{uesrInfo.surname}}{{uesrInfo.firstName}}</span>
                    <strong>{{uesrInfo.hubId}}</strong>
                </div>
                <div class="right">{{uesrInfo.jobRole}}</div>
            </div>
            <!-- <div class="box-bottom">
            </div> -->
        </div>
        <div class="video-box" @click="gmImgClick()">
            <img :src="`${aliyunOssUrl}/base/training/gm_banner.jpg`"  alt="" />
        </div>
        <div class="video-box" @click="trainingImgClick()">
            <img :src="`${aliyunOssUrl}/base/training/learning_banner.jpg`"  alt="" />
        </div>
    </div>
</template>
<script>
import {Landing} from '@/network'

export default {
    data() {
        return {
            hubId: null,
            phone: null,
            uesrInfo: {},
            aliyunOssUrl: process.env.VUE_APP_ALIYUN_OSS_URL
        }
    },
    async created() {
        this.hubId = sessionStorage.getItem('hubId')
        this.phone = sessionStorage.getItem('phone')
        this.uesrInfo = await Landing.getByHubId({hubId: this.hubId})
        // if (this.uesrInfo && this.uesrInfo.code == 401) {
        //     this.$router.replace({name: 'Login', params: {hubId: this.hubId, phone: this.phone}})
        // }
    },
    methods: {
        trainingImgClick() {
            this.$router.push({name: 'TrainingList'})
        },
        gmImgClick() {
            this.$router.push({name: 'GMList'})
        },
    }
}
</script>

<style lang="scss" scoped>
.finish-wrap{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.info-box {
    width: 100%;
    height: 125px;
    box-sizing: border-box;
    padding: 0 25px;
    background-color: rgba(0,50,32,0.7);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    .box-top {
        width: 100%;
        height: 70px;
        box-sizing: border-box;
        padding-bottom: 8px;
        border-bottom: 2px solid #fff;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 12px;
        .left {
            width: auto;
            height: auto;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            span {
                font-size: 16px;
                margin-right: 20px;
            }
            strong {
                font-weight: 400;
                font-size: 12px;
            }
        }
        .right {
            font-size: 16px;
        }
    }
    .box-bottom {
        width: 100%;
        height: auto;
        color: #fff;
        font-size: 16px;
    }
}
.video-box {
    display: flex;
    justify-content: center;
    padding: 5px 0px 5px;
    img {
        width: 100%;
        max-height: 180px;
        // border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>